import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import tickImage from "../images/tick.svg";
import { Link } from "gatsby";



const IndexPage = () => {
  return (
    <Layout>

      <SEO
        keywords={[`Website performance Optimization`, `Website development`, `Custom charts`]}
        title="Home"
      />
      <div className="px-0 mx-auto flex flex-wrap flex-col xl:flex-row items-center justify-items-center pb-6 px-3 md:px-0">
        <div className="w-full text-center my-1 text-4xl font-light text-indigo-900 leading-tight sm:text-5xl md:text-5xl lg:text-5xl">
            <h1>
                <p className="text-gray-700 font-thin pt-10 pb-4">We Offer</p>
                <p className="text-gray-700 pb-4 font-extrabold">Performance <span className="text-indigo-700">Optimization <span className="font-thin">&</span> SEO</span></p>
                <p className="text-gray-700 pb-4 font-extrabold">Website development</p>
                <p className="text-gray-700 pb-4 font-extrabold">Custom charts</p>
                <div className="text-3xl font-thin mt-4 mb-8 text-indigo-600">
                    Contact us for a <span className="underline font-light" style={{ "textDecorationColor": 'rgba(248, 113, 113)'}}>free</span> no-obligation <span className="font-light underline" style={{ "textDecorationColor": 'rgba(248, 113, 113)'}}>performance audit</span> of your site.
                </div>
            </h1>
        </div>
        <div className="w-full text-center pt-5 pb-5">
            <Link
                to="/contact"
                href="/contact"
                className="bg-gray-700 text-white font-semibold rounded-full my-6 py-4 px-8 shadow-2xl focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out"
            >
                Contact Us
            </Link>
          </div>  
      </div>  

    <svg height="1px" width="100%">
        <line
          x1={0}
          y1="0%"
          x2="100%"
          y2="10%"
          style={{ stroke: "#000", strokeWidth: ".1m"}}
        />
        
      </svg>
        <div className="w-full pb-12 pt-12 md:pb-20 md:pt-20 px-0 md:px-8 mx-auto flex flex-wrap flex-col md:flex-row items-center perf-section-background">
              <div className="pl-2 md:pl-20 pr-2 md:pr-20">
                <p className="px-1 text-3xl md:text-5xl font-medium text-indigo-900">Website performance</p>
                <ul>
                  <li className="text-indigo-900 pb-4 pt-4"><span className="text-xl md:text-3xl lg:text-3xl pt-2 px-2 md:px-4 font-light"><img className="inline" width="31px" height="31px" src={tickImage} alt=""/><span className="pl-2">Increases <span className="font-bold">revenue</span></span></span></li>
                  <li className="text-indigo-900 pb-4"><span className="text-xl md:text-3xl lg:text-3xl pt-2 px-2 md:px-4 font-light"><img className="inline " width="31px" height="31px" src={tickImage} alt="" /><span className="pl-2">Improves <span className="font-bold">search engine</span> ranking</span></span></li>
                  <li className="text-indigo-900 pb-4"><span className="text-xl md:text-3xl lg:text-3xl pt-2 px-2 md:px-4 font-light"><img className="inline " width="31px" height="31px" src={tickImage} alt="" /><span className="pl-2">Improves <span className="font-bold">user retention</span></span></span></li>
                  <li className="text-indigo-900 pb-4"><span className="text-xl md:text-3xl lg:text-3xl pt-2 px-2 md:px-4 font-light"><img className="inline " width="31px" height="32px" src={tickImage} alt="" /><span className="pl-2">Offers better <span className="font-bold">UX</span></span></span></li>
                </ul>
              </div>
        </div>
    </Layout>
  );
};

export default IndexPage;
